import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.REACT_APP_API === 'https://cms.hustle.co.id') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.info = () => { }
  console.warn = () => { }
}

root.render(
  <React.Fragment>
    <App />
    </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
